.cp-grid-responsive-columns {
  grid-template-columns: auto auto auto;
}

@media screen and (width <= 1100px) {
  .cp-grid-responsive-columns {
    grid-template-columns: auto auto;
  }
}

@media screen and (width <= 768px) {
  .cp-grid-responsive-columns {
    grid-template-columns: auto;
  }
}

.cp-grid-responsive-columns-2 {
  grid-template-columns: auto auto;
}

@media screen and (width <= 1100px) {
  .cp-grid-responsive-columns-2 {
    grid-template-columns: auto;
  }
}
/*# sourceMappingURL=index.33661855.css.map */
