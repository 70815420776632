.cp-grid-responsive-columns {
    grid-template-columns: auto auto auto;
}
@media screen and (max-width: 1100px) {
    .cp-grid-responsive-columns {
        grid-template-columns: auto auto;
    }
}
@media screen and (max-width: 768px) {
    .cp-grid-responsive-columns {
        grid-template-columns: auto;
    }
}

.cp-grid-responsive-columns-2 {
    grid-template-columns: auto auto;
}
@media screen and (max-width: 1100px) {
    .cp-grid-responsive-columns-2 {
        grid-template-columns: auto;
    }
}
